// LandingPage
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { LandingNavbar } from '../../components';
import Image from 'next/image';
import vanImage from '../../assets/header-home.png';
import { captureException, captureMessage } from '@sentry/nextjs';
import { useUser } from '@auth0/nextjs-auth0/client';

export default function LandingPage() {
  const router = useRouter();
  const { user, error, isLoading } = useUser();

  useEffect(() => {
    if (error) {
      captureException(error);
      captureMessage('Error fetching user');

      return;
    }

    if (!isLoading && user) {
      // User is authenticated, proceed with additional checks or redirect
      router.push('/landing/loading'); // Redirecting to loading page for further processing
    }
  }, [user, isLoading, error, router]);

  return (
    <div>
      <LandingNavbar />
      <div className="bg-[#EDEDDB]">
        <Image src={vanImage} alt="Go Cubeless" className="w-full" priority />
      </div>
      <div className="bg-[#2B3D45]">
        <p className="text-white text-xs pt-28 pb-4 pl-4">&copy;2023 ModSquad, Inc</p>
      </div>
    </div>
  );
}
