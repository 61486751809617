import styles from '../styles/Home.module.css';
import LandingPage from './landing/landingPage';

export default function Home() {
  return (
    <div className={styles.container}>
      <LandingPage />
    </div>
  );
}
